<template>
  <b-container class="centralize">
    <b-card body-class="bg-card" class="card-border">
      <PageTitle :title="title" />
      <div class="centralize-items">
        <p>Seu cadastro na FILA DE ESPERA ABDA foi realizado com sucesso.</p>
        <p>Por favor, aguarde o contato da secretaria.</p>
        <p>Não se esqueça de manter seu telefone de contato atualizado neste cadastro. Obrigado!</p>
      </div>
      <BackToDashboardButton />
    </b-card>
  </b-container>
</template>

<script>
import PageTitle from '@/components/pageTitle/PageTitle.vue'
import { mixin } from '@/shared/mixins'
import BackToDashboardButton from '@/components/backToDashboardButton/backToDashboardButton.vue'

export default {
  name: 'SendedForm',

  components: {
    [PageTitle.name]: PageTitle,
    [BackToDashboardButton.name]: BackToDashboardButton
  },

  mixins: [mixin],

  data: () => ({
    title: 'Formulário enviado'
  }),

  beforeRouteEnter(to, from, next) {
    if(from.name === 'Confirmation') {
      return next(vm => {
        vm.clearState()
      })
    }

    return next(false)
  }
}
</script>

<style lang="scss">
  .centralize-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--dark);
    font-family: 'Raleway', 'sans-serif';
  }
</style>
